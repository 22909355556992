var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-gray-F7F8F9 padding" }, [
    _c(
      "div",
      {
        staticClass: "text-center",
        staticStyle: { padding: "100px 30px 100px 30px" },
      },
      [
        _c("div", [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "text-black text-xl margin-tb text-bold" }, [
            _vm._v("快速创建试用小程序确认"),
          ]),
          _vm._v(" "),
          _vm.shop
            ? _c("div", { staticClass: "text-black text-df margin-tb" }, [
                _vm._v(
                  "\n        创建你的微信号将是【" +
                    _vm._s(_vm.shop.name) +
                    "】的小程序管理员\n      "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "text-gray margin-top text-sm" }, [
            _vm._v("请不要扫描来源不明的二维码"),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", [
      _c(
        "div",
        {
          staticClass:
            "bg-green radius response padding-tb text-white text-center text-df",
          on: { click: _vm.create },
        },
        [_vm._v("立即创建")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "cu-modal bottom-modal overflow-hidden",
        class: _vm.dialogVisable ? "show" : "",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            _vm.dialogVisable = false
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "cu-dialog padding-lr-lg padding-tb bg-white",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.dialogVisable = true
              },
            },
          },
          [
            _c("div", [
              _c("span", { staticClass: "text-df text-black" }, [
                _vm._v("填写试用小程序名称"),
              ]),
              _vm._v(" "),
              _c("i", {
                staticClass: "cuIcon-close text-lg",
                staticStyle: { float: "right" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.dialogVisable = false
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "margin-top-xxxl padding-lr-xxxxl" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name",
                  },
                ],
                staticClass:
                  "border radius-xs padding-tb-xs response padding-left-sm text-df",
                staticStyle: { border: "solid 1px #F6F6F6" },
                attrs: { type: "input", placeholder: "请输入试用小程序名称" },
                domProps: { value: _vm.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.name = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: " padding-lr margin-top-xxxl margin-bottom" },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "cu-btn bg-green radius-sm response padding-tb-lg",
                    attrs: { disabled: _vm.disabled },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.save.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("下一步")]
                ),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: {
          src: "https://cdn.eqiday.com/yqdshop/bg-computer.png",
          width: "150",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }